@tailwind components;
@tailwind base;
@tailwind utilities;

@layer base {
    /* Tables ---------------------------- */

    /* cart and margin tables */

    .products_table {
        @apply mb-8 w-full text-xs
    }

    .products_table thead th {
        @apply bg-tertiary font-normal
    }

    .products_table td {
        @apply border-b border-gray-200
    }

    .products_table tfoot td {
        @apply bg-tertiary
    }

    .products_table .zoom:hover {
        @apply shadow scale-500 ease-in-out duration-300;
    }

    /* only margin table */

    .margin_table thead tr:first-child th:nth-child(2) {
        @apply bg-table-secondary font-normal border-b border-gray-100
    }

    .margin_table thead tr:first-child th:last-child {
        @apply bg-tertiary font-normal border-b border-gray-200
    }

    .margin_table th:nth-child(1n+15):not(th:last-child) {
        @apply bg-table-secondary border-b border-gray-100 font-normal
    }

    .margin_table th:nth-child(15):not(th:last-child) {
        @apply border-b border-table-secondary
    }

    .margin_table tfoot td:nth-child(1n+15):not(td:last-child) {
        @apply bg-table-secondary font-normal
    }

    .margin_table tfoot tr:last-child,
    .margin_table tfoot tr:last-child td {
        @apply bg-white border-white
    }

    /* Form ---------------------------- */

    fieldset {
        /* @apply mb-8 */
    }

    label {
        @apply block pb-1 text-sm
    }

    select,
    textarea,
    input {
        @apply w-full p-2 border border-gray-200 placeholder:text-sm placeholder:font-light font-light
    }

    input[type="radio"],
    input[type="checkbox"] {
        @apply w-auto border-0
    }

    input[type="radio"] {
        @apply mt-[-1px];
    }

    svg {
        display: block;
    }

    /* offers dropdown menu reach library */
    .offers-dropdown[data-reach-menu-list] {
        @apply bg-white text-sm border py-2;
    }

    .offers-dropdown-items[data-reach-menu-item] {
        @apply py-2 px-3 font-light;
    }

    .offers-dropdown-items-selected[data-reach-menu-item][data-selected] {
        @apply bg-primary text-white cursor-pointer;
    }

    div:has(.modal-selector) {
        @apply z-50
    }

    input::-webkit-datetime-edit-day-field:focus,
    input::-webkit-datetime-edit-month-field:focus,
    input::-webkit-datetime-edit-year-field:focus {
        background-color: royalblue;
        color: white;
        outline: none;
    }
}